<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-progress-linear v-if="!etb.infos" indeterminate color="primary"></v-progress-linear>

      <v-card class="opa" v-if="etb.infos" style="min-height: 800px" :loading="!etb.infos">
        <v-card-title>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider class="ma-2"></v-divider>

        <v-btn @click="saveFiche" color="pink" dark large fab absolute bottom right fixed style="bottom: 5px">
          <v-icon>mdi-sd</v-icon>
        </v-btn>
        <v-card-text>
          <v-dialog v-model="dialogService" width="100%" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
              <v-toolbar class="text-h5" flat dark color="primary">
                <v-toolbar-title>
                  <v-icon class="pa-2">mdi-plus-circle</v-icon> <span v-if="updateField">Ajouter</span><span
                    v-else>Modifier</span> un
                  service
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="dialogService = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-card-text>
                <v-alert type="warning" border="top" v-if="okService && titleService.length && updateField">Votre
                  service existe déjà</v-alert>
                <v-row>
                  <v-col cols="4">
                    <v-text-field v-model="titleService" label="Titre du service" prepend-icon="mdi-check">
                    </v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-select v-model="categorieService" :items="[
                      // 'restaurant',
                      'coiffeur',
                      'manucure',
                      'sauna',
                      'cinéma',
                      'fitness center',
                    ]" label="Catégorie de Service"></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-textarea v-model="descriptionService" prepend-icon="mdi-check" label="Description du service"
                      hint="Facultatif"></v-textarea>
                  </v-col>
                </v-row>
                <v-row v-if="showInversion === true">
                  <v-col>
                    <v-alert type="warning"> Les horaires de début et de fin sont inversées</v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-row class="flex flex-row">
                      <v-col cols="1">
                        <v-switch label="Lundi" class="pa-2" v-model="serviceLundi2"></v-switch>
                        <v-text-field cols="2" solo label="De" :rules="hourRules" hint="en heure"
                          prepend-icon="mdi-timer" @blur="noInversion" v-model="serviceLundiBegin"
                          :disabled="!serviceLundi2"></v-text-field>
                        <v-text-field cols="2" label="À" solo hint="en heure" v-model="serviceLundiEnd"
                          @blur="noInversion" :class="{ 'afterHour': true }" :rules="hourRules" :disabled="!serviceLundi2"
                          prepend-icon="mdi-timer"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-switch label="Mardi" class="pa-2" v-model="serviceMardi2"></v-switch>
                        <v-text-field cols="2" label="De" solo :disabled="!serviceMardi2" :rules="hourRules"
                          @blur="noInversion" v-model="serviceMardiBegin" prepend-icon="mdi-timer"></v-text-field>
                        <v-text-field cols="2" label="À" solo :disabled="!serviceMardi2" @blur="noInversion"
                          :rules="hourRules" :class="{ 'afterHour': true }" v-model="serviceMardiEnd"
                          prepend-icon="mdi-timer"></v-text-field>
                      </v-col>

                      <v-col cols="1">
                        <v-switch label="Mercredi" class="pa-2" v-model="serviceMercredi2"></v-switch>
                        <v-text-field cols="2" label="De" solo :disabled="!serviceMercredi2" :rules="hourRules"
                          @blur="noInversion" v-model="serviceMercrediBegin" prepend-icon="mdi-timer"></v-text-field>
                        <v-text-field cols="2" label="À" solo :disabled="!serviceMercredi2" :rules="hourRules"
                          @blur="noInversion" :class="{ 'afterHour': true }" v-model="serviceMercrediEnd"
                          prepend-icon="mdi-timer"></v-text-field>
                      </v-col>

                      <v-col cols="1">
                        <v-switch label="Jeudi" class="pa-2" v-model="serviceJeudi2"></v-switch>
                        <v-text-field cols="2" label="De" solo :disabled="!serviceJeudi2" :rules="hourRules"
                          @blur="noInversion" v-model="serviceJeudiBegin" prepend-icon="mdi-timer"></v-text-field>
                        <v-text-field cols="2" label="À" solo :disabled="!serviceJeudi2" :rules="hourRules"
                          @blur="noInversion" :class="{ 'afterHour': true }" v-model="serviceJeudiEnd"
                          prepend-icon="mdi-timer"></v-text-field>
                      </v-col>

                      <v-col cols="1">
                        <v-switch label="Vendredi" class="pa-2" v-model="serviceVendredi2"></v-switch>
                        <v-text-field cols="2" label="De" solo :disabled="!serviceVendredi2" @blur="noInversion"
                          v-model="serviceVendrediBegin" :rules="hourRules" prepend-icon="mdi-timer"></v-text-field>
                        <v-text-field cols="2" label="À" @blur="noInversion" :class="{ 'afterHour': true }" solo
                          :disabled="!serviceVendredi2" v-model="serviceVendrediEnd" :rules="hourRules"
                          prepend-icon="mdi-timer"></v-text-field>
                      </v-col>

                      <v-col cols="1">
                        <v-switch label="Samedi" class="pa-2" v-model="serviceSamedi2"></v-switch>
                        <v-text-field cols="2" label="De" solo :disabled="!serviceSamedi2" v-model="serviceSamediBegin"
                          @blur="noInversion" :rules="hourRules" prepend-icon="mdi-timer"></v-text-field>
                        <v-text-field cols="2" label="À" solo :disabled="!serviceSamedi2" v-model="serviceSamediEnd"
                          @blur="noInversion" :class="{ 'afterHour': true }" :rules="hourRules" prepend-icon="mdi-timer">
                        </v-text-field>
                      </v-col>

                      <v-col cols="1">
                        <v-switch label="Dimanche" class="pa-2" v-model="serviceDimanche2"></v-switch>
                        <v-text-field cols="2" label="De" solo :disabled="!serviceDimanche2" @blur="noInversion"
                          v-model="serviceDimancheBegin" :rules="hourRules" prepend-icon="mdi-timer"></v-text-field>
                        <v-text-field cols="2" label="À" solo :disabled="!serviceDimanche2" @blur="noInversion"
                          :class="{ 'afterHour': true }" v-model="serviceDimancheEnd" :rules="hourRules"
                          prepend-icon="mdi-timer"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-btn v-if="updateField" block color="primary" large @click="storeService" :disabled="okService">
                  <v-icon>mdi-add</v-icon>
                  Enregistrer ce service
                </v-btn>

                <v-btn v-else block color="warning" large @click="updatedService">
                  <v-icon>mdi-pencil</v-icon>
                  Modifier ce service
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog fullscreen hide-overlay v-model="dialog" width="100%" height="900">
            <v-card>
              <v-toolbar class="text-h5" flat dark color="primary">
                <v-toolbar-title>
                  <v-icon class="pa-2">mdi-pencil</v-icon> Modifier une
                  animation
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <v-card-text>
                <v-text-field append-icon="mdi-pencil" v-model="titleEvent"></v-text-field>

                <v-row>
                  <v-col class="col-lg-4 col-md-6 col-sm-12">
                    <v-date-picker landscape elevation="2" v-model="pickerEvent"></v-date-picker>
                  </v-col>

                  <v-col class="col-lg-4 col-md-6 col-sm-12">
                    <v-time-picker min="6:00"
       landscape elevation="2" v-model="timeEvent" format="24hr"
       :max="notification ? '20:00' : '23:00'"
                      :allowed-minutes="allowedStep" scrollable></v-time-picker>
                  </v-col>

                  <v-col class="col-lg-4 col-md-6 col-sm-12">
                    <v-time-picker landscape v-model="timeEndEvent" :allowed-minutes="allowedStep" format="24hr"
                      scrollable min="6:00"  :max="notification ? '20:00' : '23:00'" elevation="2"></v-time-picker>
                  </v-col>

                  <v-col class="col-lg-4 col-md-6 col-sm-12">
                    <v-checkbox
                      v-model="notification"
                      label="L'activité sera rappelée sur tous les appareils de l'établissement 15 minutes avant."
                      color="red"
                    ></v-checkbox>
                    <p><strong>Attention pas de notification possible entre 20h00 et 6h00</strong></p>
                  </v-col>


                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="updateEvent">
                  <v-icon>mdi-sd</v-icon>
                  Enregistrer cette animation
                </v-btn>

                <v-btn outlined color="red" @click="removeEvent">
                  <v-icon>mdi-delete</v-icon>
                  Supprimer cette animation
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog fullscreen hide-overlay v-model="dialogAdd" width="100%">
            <v-card>
              <v-toolbar class="text-h5" flat dark color="primary">
                <v-toolbar-title>
                  <v-icon class="ma-2">mdi-plus</v-icon>{{ dialogAddTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="dialogAdd = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              
              <v-card-text>
                <v-text-field v-model="titleEvent" label="Nom de l'animation" hint="Attention cela sera lue par Alexa"
                  variant="contained" required append-icon="mdi-pencil"></v-text-field>
                <v-row>
                  <v-col class="col-lg-4 col-md-6 col-sm-12">
                    <v-date-picker landscape elevation="2" v-model="pickerEvent"></v-date-picker>
                  </v-col>
                  <v-col class="col-lg-4 col-md-6 col-sm-12">
                    <v-time-picker landscape elevation="2" v-model="timeEvent" format="24hr"
                      :allowed-minutes="allowedStep" scrollable  min="6:00" :max="notification ? '20:00' : '23:00'"></v-time-picker>
                  </v-col>
                  <v-col class="col-lg-4 col-md-6 col-sm-12">
                    <v-time-picker landscape v-model="timeEndEvent" :allowed-minutes="allowedStep" format="24hr"
                      scrollable  min="6:00" :max="notification ? '20:00' : '23:00'" elevation="2"></v-time-picker>
                  </v-col>

                  <v-col class="col-lg-4 col-md-6 col-sm-12">
                    <v-checkbox
                      :disabled="disabledNotif"
                      v-model="notification"
                      label="L'activité sera rappelée sur tous les appareils de l'établissement 15 minutes avant."
                      color="red"
                    ></v-checkbox>
                    <v-alert dark color="red"><strong>Attention pas de notification possible entre 20h00 et 6h00</strong></v-alert>

                  </v-col>

                
                </v-row>
              </v-card-text>
              <v-card-actions class="pa-2">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="storeNewEvent">
                  <v-icon>mdi-sd</v-icon>
                  Enregistrer cette animation
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-form ref="form" v-if="etb && etb.infos">
            <v-spacer class="ma-10"></v-spacer>
            <h1 ref="menu" id="menu">
              <v-icon class="pa-2">mdi-apps</v-icon>Les Menus du restaurant
            </h1>
            <br />
            <v-alert border="left" shaped color="cyan" icon="mdi-album" colored-border type="info" elevation="2"
              class="grey--text">
              <h2 class="pa-2">Exemple</h2>
              - <strong>Résident : </strong> <i>Alexa, quel est le menu de ce midi ?</i> <br />
              - <strong>Alexa : </strong> <i>Ce midi, ...</i>
            </v-alert>

            <div class="d-flex justify-center mb-6">
              <v-radio-group v-model="weekSwitch" row>
                <v-radio label="Semaine en cours" value="week" color="secondary"></v-radio>
                <v-radio label="Semaine suivante" value="next" color="secondary"></v-radio>
              </v-radio-group>
            </div>

            <v-card dark class="ma-5 pa-4 primary">
              <h3 v-if="weekSwitch === 'week'">
                Lundi
                {{
                    `${dateNow[0].format("D")}/${dateNow[0]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <h3 v-else>
                Lundi
                {{
                    `${dateNext[0].format("D")}/${dateNext[0]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <v-row>
                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model='etb.menu.lundi.lunch' @blur="saveFiche" label="Déjeuner"
                    v-if="weekSwitch == 'week'" hint="Ce texte sera lu à la voix" required></v-textarea>

                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.lundi.lunchnext" v-else @blur="saveFiche"
                    label="Déjeuner" hint="Ce texte sera lu à la voix" required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.lundi.afternoon" label="Goûter" @blur="saveFiche"
                    v-if="weekSwitch == 'week'" hint="Ce texte sera lu à la voix" required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.lundi.afternoonnext" label="Goûter"
                    @blur="saveFiche" hint="Ce texte sera lu à la voix" v-else required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.lundi.diner" label="Dîner" @blur="saveFiche"
                    v-if="weekSwitch == 'week'" hint="Ce texte sera lu à la voix" required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.lundi.dinernext"
                    hint="Ce texte sera lu à la voix" label="Dîner" @blur="saveFiche" v-else required></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="ma-4"></v-divider>

              <h3 v-if="weekSwitch === 'week'">
                Mardi
                {{
                    `${dateNow[1].format("D")}/${dateNow[1]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <h3 v-else>
                Mardi
                {{
                    `${dateNext[1].format("D")}/${dateNext[1]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <v-row>
                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mardi.lunch" label="Déjeuner" @blur="saveFiche"
                    v-if="weekSwitch == 'week'" hint="Ce texte sera lu à la voix" required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mardi.lunchnext" label="Déjeuner"
                    @blur="saveFiche" hint="Ce texte sera lu à la voix" v-else required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mardi.afternoon" label="Goûter"
                    v-if="weekSwitch == 'week'" hint="Ce texte sera lu à la voix" @blur="saveFiche" required>
                  </v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mardi.afternoonnext"
                    hint="Ce texte sera lu à la voix" label="Goûter" v-else @blur="saveFiche" required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mardi.diner" label="Dîner"
                    v-if="weekSwitch == 'week'" @blur="saveFiche" hint="Ce texte sera lu à la voix" required>
                  </v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mardi.dinernext"
                    hint="Ce texte sera lu à la voix" label="Dîner" v-else @blur="saveFiche" required></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="ma-4"></v-divider>

              <h3 v-if="weekSwitch === 'week'">
                Mercredi
                {{
                    `${dateNow[2].format("D")}/${dateNow[2]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <h3 v-else>
                Mercredi
                {{
                    `${dateNext[2].format("D")}/${dateNext[2]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <v-row>
                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mercredi.lunch" label="Déjeuner"
                    @blur="saveFiche" hint="Ce texte sera lu à la voix" v-if="weekSwitch == 'week'" required>
                  </v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mercredi.lunchnext"
                    hint="Ce texte sera lu à la voix" label="Déjeuner" @blur="saveFiche" v-else required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mercredi.afternoon"
                    hint="Ce texte sera lu à la voix" v-if="weekSwitch == 'week'" label="Goûter" @blur="saveFiche"
                    required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mercredi.afternoonnext"
                    hint="Ce texte sera lu à la voix" v-else label="Goûter" @blur="saveFiche" required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mercredi.diner" hint="Ce texte sera lu à la voix"
                    label="Dîner" @blur="saveFiche" v-if="weekSwitch == 'week'" required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.mercredi.dinernext"
                    hint="Ce texte sera lu à la voix" label="Dîner" @blur="saveFiche" v-else required></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="ma-4"></v-divider>

              <h3 v-if="weekSwitch === 'week'">
                Jeudi
                {{
                    `${dateNow[3].format("D").padStart(2, "0")}/${dateNow[3]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <h3 v-else>
                Jeudi
                {{
                    `${dateNext[3].format("D").padStart(2, "0")}/${dateNext[3]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <v-row>
                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.jeudi.lunch" hint="Ce texte sera lu à la voix"
                    label="Déjeuner" @blur="saveFiche" required v-if="weekSwitch == 'week'"></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.jeudi.lunchnext"
                    hint="Ce texte sera lu à la voix" v-else label="Déjeuner" @blur="saveFiche" required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.jeudi.afternoon"
                    hint="Ce texte sera lu à la voix" label="Goûter" @blur="saveFiche" v-if="weekSwitch == 'week'"
                    required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.jeudi.afternoonnext"
                    hint="Ce texte sera lu à la voix" label="Goûter" @blur="saveFiche" v-else required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.jeudi.diner" hint="Ce texte sera lu à la voix"
                    label="Dîner" @blur="saveFiche" v-if="weekSwitch == 'week'" required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.jeudi.dinernext"
                    hint="Ce texte sera lu à la voix" label="Dîner" v-else @blur="saveFiche" required></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="ma-4"></v-divider>

              <h3 v-if="weekSwitch === 'week'">
                Vendredi
                {{
                    `${dateNow[4].format("D").padStart(2, "0")}/${dateNow[4]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <h3 v-else>
                Vendredi
                {{
                    `${dateNext[4].format("D").padStart(2, "0")}/${dateNext[4]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <v-row>
                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.vendredi.lunch" hint="Ce texte sera lu à la voix"
                    label="Déjeuner" @blur="saveFiche" v-if="weekSwitch == 'week'" required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.vendredi.lunchnext"
                    hint="Ce texte sera lu à la voix" label="Déjeuner" v-else @blur="saveFiche" required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.vendredi.afternoon"
                    hint="Ce texte sera lu à la voix" label="Goûter" @blur="saveFiche" v-if="weekSwitch == 'week'"
                    required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.vendredi.afternoonnext"
                    hint="Ce texte sera lu à la voix" label="Goûter" v-else @blur="saveFiche" required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.vendredi.diner" hint="Ce texte sera lu à la voix"
                    label="Dîner" @blur="saveFiche" v-if="weekSwitch == 'week'" required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.vendredi.dinernext"
                    hint="Ce texte sera lu à la voix" v-else label="Dîner" @blur="saveFiche" required></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="ma-4"></v-divider>

              <h3 v-if="weekSwitch === 'week'">
                Samedi
                {{
                    `${dateNow[5].format("D").padStart(2, "0")}/${dateNow[5]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <h3 v-else>
                Samedi
                {{
                    `${dateNext[5].format("D").padStart(2, "0")}/${dateNext[5]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>

              <v-row>
                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" hint="Ce texte sera lu à la voix" v-model="etb.menu.samedi.lunch"
                    label="Déjeuner" @blur="saveFiche" v-if="weekSwitch == 'week'" required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.samedi.lunchnext"
                    hint="Ce texte sera lu à la voix" label="Déjeuner" @blur="saveFiche" v-else required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.samedi.afternoon"
                    hint="Ce texte sera lu à la voix" label="Goûter" required @blur="saveFiche"
                    v-if="weekSwitch == 'week'"></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.samedi.afternoonnext"
                    hint="Ce texte sera lu à la voix" label="Goûter" required v-else @blur="saveFiche"></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.samedi.diner" hint="Ce texte sera lu à la voix"
                    label="Dîner" required @blur="saveFiche" v-if="weekSwitch == 'week'"></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.samedi.dinernext"
                    hint="Ce texte sera lu à la voix" label="Dîner" required @blur="saveFiche" v-else></v-textarea>
                </v-col>
              </v-row>
              <v-divider class="ma-4"></v-divider>

              <h3 v-if="weekSwitch === 'week'">
                Dimanche
                {{
                    `${dateNow[6].format("D").padStart(2, "0")}/${dateNow[6]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <h3 v-else>
                Dimanche
                {{
                    `${dateNext[6].format("D").padStart(2, "0")}/${dateNext[6]
                      .format("M")
                      .padStart(2, "0")}`
                }}
              </h3>
              <v-row>
                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.dimanche.lunch" hint="Ce texte sera lu à la voix"
                    label="Déjeuner" required @blur="saveFiche" v-if="weekSwitch == 'week'"></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.dimanche.lunchnext"
                    hint="Ce texte sera lu à la voix" label="Déjeuner" required @blur="saveFiche" v-else></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.dimanche.afternoon"
                    hint="Ce texte sera lu à la voix" label="Goûter" @blur="saveFiche" v-if="weekSwitch == 'week'"
                    required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.dimanche.afternoonnext"
                    hint="Ce texte sera lu à la voix" v-else label="Goûter" @blur="saveFiche" required></v-textarea>
                </v-col>

                <v-col cols="4">
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.dimanche.diner" hint="Ce texte sera lu à la voix"
                    label="Dîner" @blur="saveFiche" v-if="weekSwitch == 'week'" required></v-textarea>
                  <v-textarea append-icon="mdi-pencil" v-model="etb.menu.dimanche.dinernext"
                    hint="Ce texte sera lu à la voix" label="Dîner" v-else @blur="saveFiche" required></v-textarea>
                </v-col>
              </v-row>
            </v-card>

            <v-spacer class="ma-10"></v-spacer>
            <h1 ref="animations" id="animations">
              <v-icon class="pa-2">mdi-apps</v-icon>Les Animations
            </h1>
            <br />

            <v-alert border="left" shaped color="cyan" icon="mdi-album" colored-border type="info" elevation="2"
              class="grey--text">
              <h2 class="pa-2">Exemple</h2>
              - <strong>Résident : </strong> <i>Alexa, quelles sont les animations du jour ?</i> <br />
              - <strong>Alexa : </strong> <i>Aujourd'hui, atelier ... à 14h.</i>
            </v-alert>


            <v-sheet tile class="mt-4 d-flex flex-column mb-6">
              <v-btn class="float-right" elevation="2" large @click="addAnimation" color="primary">
                <v-icon dark>mdi-plus</v-icon> Ajouter une Animation
              </v-btn>
            </v-sheet>

            <v-btn v-if="typeCal == 'week' && events.length" class="float-right" elevation="2" large
              @click="monthAnimation" color="secondary">
              <v-icon dark>mdi-calendar-month</v-icon> Vue au mois
            </v-btn>

            <v-btn v-if="typeCal == 'month' && events.length" class="float-right" elevation="2" large
              @click="weekAnimation" color="secondary">
              <v-icon dark>mdi-calendar-week</v-icon> Vue à la semaine
            </v-btn>

            <v-sheet v-if="events.length" tile class="d-flex flex-row justify-end mb-6">
              <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon class="ma-2" @click="nextCal">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>

            <v-calendar v-if="events.length" ref="calendar" interval-minutes="30" v-model="value" color="primary"
              :type="typeCal" :events="events" :event-color="getEventColor" :event-ripple="true" first-interval="13"
              :interval-count="36" @click:date="addAnimationMonth" @click:event="showEvent">
                <template v-slot:event="{ event }">
                      <h3 class="pa-2"> <v-icon class="mr-2" v-if="event.notification"  small color="white">
                        mdi-alarm
                      </v-icon><span >{{ event.name}}</span></h3>
                </template>
            </v-calendar>

            <v-row v-else class="ma-8 d-flex row flex-no-wrap justify-space-between">
              <v-alert outlined style="width: 100%" type="info" dark color="primary">Pas encore d'animations</v-alert>
            </v-row>

            <v-spacer class="ma-10"></v-spacer>
            <h1 id="services">
              <v-icon class="pa-2">mdi-apps</v-icon>Les Services
            </h1>
            <br />
            <v-alert border="left" shaped color="cyan" icon="mdi-album" colored-border type="info" class="grey--text"
              elevation="2">
              <h2 class="pa-2">Exemple</h2>
              - <strong>Résident : </strong> <i>Alexa, à quelle heure ouvre le coiffeur ?</i> <br />
              - <strong>Alexa : </strong> <i>Le coiffeur est ouvert le lundi de 10h à 14h.</i>
            </v-alert>
            <v-row v-if="services.length" class="ma-8 d-flex row flex-no-wrap justify-space-between">
              <v-card v-for="service in services" :key="service._id" class="mr-2" max-width="300">

                <v-img height="150" :src="imgService(service.category.toLowerCase())"></v-img>
                <v-chip variant="outlined" color="primary" class="ma-3" text-color="white" label>
                  <v-icon left icon="mdi-label"></v-icon>{{ service.category }}
                </v-chip>
                <v-card-title>{{ service.title }}</v-card-title>

                <v-card-text>
                  <v-row align="center" class="mx-0">
                    <div class="my-4 text-subtitle-1">
                      {{ service.description }}
                    </div>
                  </v-row>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-title>
                  <v-icon class="pa-2">mdi-timer</v-icon>Disponibilités
                </v-card-title>

                <v-card-text>
                  <v-chip v-for="(horaire, index) in service.horaires.filter(
                    (e) => e.begin && e.begin !== ''
                  )" :key="index" label class="secondary white--text ma-1 pa-4">
                    <v-icon class="mr-1" dark>mdi-alarm</v-icon>
                    {{ horaire.title }}: {{ horaire.begin }}-{{
                        horaire.end
                    }}
                  </v-chip>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn color="secondary" big @click="updateService(service)">
                    <v-icon>mdi-pencil</v-icon> Modifier
                  </v-btn>


                  <v-btn color="red darken-2" text @click="removeService(service.title)">
                    <v-icon>mdi-delete</v-icon>
                    Supprimer
                  </v-btn>
                </v-card-actions>
              </v-card>

              <v-col height="600" class="ma-2 pt-8">
                <v-spacer class="ma-4"></v-spacer>
                <v-btn class="mt-12 ml-10" fab large dark color="secondary" @click="
                  reseting();
                dialogService = true;
                updateField = true;
                ">
                  <v-icon dark> mdi-plus-circle </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
              </v-col>
            </v-row>

            <v-row v-else class="ma-8 d-flex row flex-no-wrap justify-space-between">
              <v-alert outlined style="width: 100%" type="info" dark color="primary">Pas encore de services</v-alert>
              <v-btn block large dark color="primary" @click="
                dialogService = true;
              updateField = true;
              ">
                <v-icon dark> mdi-plus </v-icon>
                Ajouter un service
              </v-btn>
            </v-row>

            <v-divider class="ma-2"></v-divider>

            <v-spacer class="ma-10"></v-spacer>
            <v-divider></v-divider>
            <v-spacer class="ma-4 pa-2"></v-spacer>
            <h1 id="informations">
              <v-icon class="pa-2">mdi-apps</v-icon>Les Informations de l'établissement
            </h1>
            <br />
            <v-alert border="left" shaped color="cyan" icon="mdi-album" colored-border type="info" elevation="2"
              class="grey--text">
              <h2 class="pa-2">Exemple</h2>
              - <strong>Résident : </strong> <i>Alexa, dans quelle ville je suis ?</i> <br />
              - <strong>Alexa : </strong> <i>Vous êtes actuellement dans l'établissement ... situé à Paris.</i>
            </v-alert>

            <v-alert border="left" color="pink" icon="mdi-alert" colored-border type="warning" elevation="2">
              <i>Ce texte sera lu à la voix par Alexa sur demande du résident</i>

            </v-alert>

            <v-col cols="12">
              <v-text-field  @blur="saveFiche" append-icon="mdi-pencil" v-model="etb.infos.name" label="Nom de l'établissement"
                :rules="[rules.required]" hint="Ce nom peut-être lu avec Alexa" variant="contained" required>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea @blur="saveFiche"  append-icon="mdi-pencil" v-model="etb.infos.description"
                label="Description de l'établissement" required></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-textarea @blur="saveFiche"  append-icon="mdi-pencil" v-model="etb.infos.descriptionRoom" label="Description de la chambre"
                required></v-textarea>
            </v-col>

            <v-row>
              <v-col cols="4">
                <v-text-field @blur="saveFiche"  append-icon="mdi-city" v-model="etb.infos.city" :rules="[rules.required]"
                  label="Ville de l'établissement" required></v-text-field>
              </v-col>

              <v-col cols="2">
                <v-text-field @blur="saveFiche"  append-icon="mdi-city" v-model="etb.infos.cp" :rules="[rules.required]"
                  label="CP de l'établissement" required></v-text-field>
              </v-col>

              <v-col cols=".">
                <v-text-field @blur="saveFiche"  append-icon="mdi-map" v-model="etb.infos.address" :rules="[rules.required]"
                  label="Adresse de l'établissement" required></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card elevation="2" class="pa-2">
                  <v-card-title>
                    <v-icon class="ma-1">mdi-timer</v-icon> Horaires
                    d'ouvertures de l'établissement
                  </v-card-title>
                  <v-alert border="left" shaped color="cyan" icon="mdi-album" colored-border type="info" elevation="2"
                    class="grey--text">
                    <h2 class="pa-2">Exemple</h2>
                    - <strong>Résident : </strong> <i>Alexa, à quelle heure ouvre l'établissement ?</i> <br />
                    - <strong>Alexa : </strong> <i>L'établissement est ouvert le lundi de 8h à 19h.</i>

                  </v-alert>
                  <v-row>
                    <v-col cols="3">
                      <v-switch label="Lundi" class="pa-2" v-model="serviceLundi"></v-switch>
                      <v-text-field  @blur="saveFiche"  cols="2" solo label="De" v-model="etb.horaires.lundi.begin"
                        :disabled="!serviceLundi" :rules="hourRules" hint="Ex: 8h30" prepend-icon="mdi-alarm">
                      </v-text-field>
                      <div class="mb-4 ml-4"><strong>à</strong></div>
                      <v-text-field @blur="saveFiche"  cols="2" label="À" solo hint="Ex: 8h30" v-model="etb.horaires.lundi.end"
                        :disabled="!serviceLundi" :rules="hourRules" prepend-icon="mdi-alarm"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-switch label="Mardi" class="pa-2" v-model="serviceMardi"></v-switch>
                      <v-text-field  @blur="saveFiche"  cols="2" label="De" solo v-model="etb.horaires.mardi.begin"
                        :disabled="!serviceMardi" :rules="hourRules" prepend-icon="mdi-alarm" hint="Ex: 8h30">
                      </v-text-field>
                      <div class="mb-4 ml-4"><strong>à</strong></div>
                      <v-text-field @blur="saveFiche"  cols="2" label="A" solo v-model="etb.horaires.mardi.end" :disabled="!serviceMardi"
                        prepend-icon="mdi-alarm" :rules="hourRules" hint="Ex: 8h30"></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-switch label="Mercredi" class="pa-2" v-model="serviceMercredi"></v-switch>
                      <v-text-field @blur="saveFiche"  cols="2" label="De" solo v-model="etb.horaires.mercredi.begin"
                        :disabled="!serviceMercredi" :rules="hourRules" prepend-icon="mdi-alarm" hint="Ex: 8h30">
                      </v-text-field>
                      <div class="mb-4 ml-4"><strong>à</strong></div>
                      <v-text-field @blur="saveFiche"  cols="2" label="À" solo v-model="etb.horaires.mercredi.end"
                        :disabled="!serviceMercredi" :rules="hourRules" prepend-icon="mdi-alarm" hint="Ex: 8h30">
                      </v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-switch label="Jeudi" class="pa-2" v-model="serviceJeudi"></v-switch>
                      <v-text-field @blur="saveFiche"  cols="2" label="De" solo :disabled="!serviceJeudi" :rules="hourRules"
                        v-model="etb.horaires.jeudi.begin" hint="Ex: 8h30" prepend-icon="mdi-alarm"></v-text-field>
                      <div class="mb-4 ml-4"><strong>à</strong></div>
                      <v-text-field @blur="saveFiche"  cols="2" label="À" solo v-model="etb.horaires.jeudi.end" :disabled="!serviceJeudi"
                        :rules="hourRules" hint="Ex: 8h30" prepend-icon="mdi-alarm"></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-switch label="Vendredi" class="pa-2" v-model="serviceVendredi"></v-switch>
                      <v-text-field @blur="saveFiche"  cols="2" label="De" solo :rules="hourRules" :disabled="!serviceVendredi"
                        v-model="etb.horaires.vendredi.begin" hint="Ex: 8h30" prepend-icon="mdi-alarm"></v-text-field>
                      <div class="mb-4 ml-4"><strong>à</strong></div>
                      <v-text-field @blur="saveFiche"  cols="2" label="À" solo :rules="hourRules" :disabled="!serviceVendredi"
                        v-model="etb.horaires.vendredi.end" prepend-icon="mdi-alarm" hint="Ex: 8h30"></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-switch label="Samedi" class="pa-2" v-model="serviceSamedi"></v-switch>
                      <v-text-field @blur="saveFiche"  cols="2" label="De" solo :disabled="!serviceSamedi" :rules="hourRules"
                        v-model="etb.horaires.samedi.begin" hint="Ex: 8h30" prepend-icon="mdi-alarm"></v-text-field>
                      <div class="mb-4 ml-4"><strong>à</strong></div>
                      <v-text-field @blur="saveFiche"  cols="2" label="À" solo v-model="etb.horaires.samedi.end" :disabled="!serviceSamedi"
                        :rules="hourRules" hint="Ex: 8h30" prepend-icon="mdi-alarm"></v-text-field>
                    </v-col>

                    <v-col cols="3">
                      <v-switch label="Dimanche" class="pa-2" v-model="serviceDimanche"></v-switch>
                      <v-text-field @blur="saveFiche"  cols="2" label="De" solo :disabled="!serviceDimanche" :rules="hourRules"
                        v-model="etb.horaires.dimanche.begin" hint="Ex: 8h30" prepend-icon="mdi-alarm"></v-text-field>
                      <div class="mb-4 ml-4"><strong>à</strong></div>
                      <v-text-field @blur="saveFiche"  cols="2" label="À" solo v-model="etb.horaires.dimanche.end" :rules="hourRules"
                        :disabled="!serviceDimanche" hint="Ex: 8h30" prepend-icon="mdi-alarm"></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card elevation="2" class="pa-4">
                  <v-card-title>
                    <v-icon class="ma-1">mdi-food</v-icon> Horaires de repas de
                    l'établissement
                  </v-card-title>
                  <v-alert border="left" shaped color="cyan" icon="mdi-album" colored-border type="info" elevation="2"
                    class="grey--text">
                    <h2 class="pa-2">Exemple</h2>
                    - <strong>Résident : </strong> <i>Alexa, à quelle heure ouvre le restaurant ?</i> <br />
                    - <strong>Alexa : </strong> <i>Le restaurant est ouvert de 12h à 14h.</i>
                  </v-alert>

                  <v-simple-table dense class="pa-12">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Petit Déjeuner</th>
                          <th class="text-left">Déjeuner</th>
                          <th class="text-left">Goûter</th>
                          <th class="text-left">Dîner</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <v-text-field @blur="saveFiche"  append-icon="mdi-alarm" label="De" hint="8h" variant="contained"
                              v-model="etb.restaurant['petit déjeuner'].begin" :rules="hourRules" required cols="2"
                              solo></v-text-field>
                          </td>
                          <td>
                            <v-text-field append-icon="mdi-alarm" v-model="etb.restaurant['déjeuner'].begin"
                              :rules="hourRules" label="De" hint="8h" variant="contained" cols="2" solo required>
                            </v-text-field>
                            <div class="mb-4">à</div>
                            <v-text-field @blur="saveFiche"  append-icon="mdi-alarm" :rules="hourRules"
                              v-model="etb.restaurant['déjeuner'].end" label="À" hint="12h" variant="contained" cols="2"
                              solo required></v-text-field>
                          </td>
                          <td>
                            <v-text-field @blur="saveFiche"  append-icon="mdi-alarm" :rules="hourRules"
                              v-model="etb.restaurant['goûter'].begin" label="De" hint="8h" variant="contained" cols="2"
                              solo required></v-text-field>
                          </td>

                          <td>
                            <v-text-field @blur="saveFiche"  append-icon="mdi-alarm" :rules="hourRules"
                              v-model="etb.restaurant['dîner'].begin" label="De" hint="8h" variant="contained" cols="2"
                              solo required></v-text-field>
                            <div class="mb-4">à</div>
                            <v-text-field @blur="saveFiche"  append-icon="mdi-alarm" :rules="hourRules"
                              v-model="etb.restaurant['dîner'].end" label="À" hint="12h" variant="contained" cols="2"
                              solo required></v-text-field>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
            <v-card elevation="2" style="padding: 10px" color="primary" dark>
              <v-card-title>
                <v-icon class="ma-1">mdi-message</v-icon> Message de
                l'établissement
              </v-card-title>
            <v-row>
              <v-alert
                type="pink"
              >
              Pas d’envoi d’annonces entre 20h et 6h
              </v-alert>
            </v-row>

              <v-row>
                <v-col cols="12">
                  <label>Mode de notification</label>
                <ul>
                  <li>Silencieuse : Tous les appareils produiront un bruit et une lumière jaune pour indiquer une notification. Le résident devra demander « quelle sont les notifications » et Alexa énoncera le message.
                    Si elle n’est pas lu, la notification disparait au bout d’une heure</li>
                  <li>Lu automatiquement : Le texte sera énoncé par Alexa sur toutes les appareils, sans notification</li>
                </ul>
                <v-radio-group v-model="modeNotification">
                  <v-radio
                      label="Silencieuse"
                      value="silence"
                    ></v-radio>
                    <v-radio
                      label="Lu automatiquement"
                      value="automatic"
                    ></v-radio>
                    </v-radio-group>
                    <hr />

                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea @blur="saveFiche"  class="pa-2" label="Votre annonce" v-model="etb.annonce"
                    hint="Ce texte sera lu à la voix par Alexa sur demande du résident"></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn :disabled="!isValidHours" @click="sendMessage" large block color="primary">
                  <v-icon class="pa-1">mdi-send</v-icon>Envoyer ce
                  message
                </v-btn>
              </v-card-actions>
            </v-card>
            <!-- <v-row>
              <v-col>
                <v-list dense>
                  <v-subheader>Rooms affectées</v-subheader>
                  <v-list-item v-for="(room, index) in rooms" :key="index">
                    <v-list-item-content two-line>
                      <v-list-item-title v-text="room.name"></v-list-item-title>
                      <v-list-item-subtitle>{{ room.id }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row> -->
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-6">
          <v-btn block large color="secondary" class="white--text mt-8" @click="saveFiche">
            <v-icon>mdi-check</v-icon>Enregistrer les informations
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-snackbar v-model="snackbar">
      Vos informations de l'établissement ont été mise à jour
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar :timeout="3000" v-model="snackbarMessage" color="red accent-2" top>{{ messageNotif }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red accent-4" text v-bind="attrs" @click="snackbarMessage = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import xhr from "../plugins/axios";
import moment from "moment";
moment.locale("fr");
import { mask } from "vue-the-mask";

const dt = new Date();
export default {
  name: "home",
  directives: { mask },
  data() {
    return {
      modeNotification: 'silence',
      hourRules: [
        v => !!v || 'Heure est requise',
        v => /([0-1]?[0-9]|2[0-3])h/.test(v) || 'Format incorrect. L’heure doit être de format type _h ou _h_. Exemple : 8h ; 16h23 ; 17h',
        v => parseInt(v) >= 1 && parseInt(v) <= 23 || 'Heure invalide',
      ],
      rules: {
        required: (value) => !!value || "Obligatoire.",
      },
      showInversion: false,
      disabledNotif: false,
      expand: true,
      dateNow: null,
      dateNext: null,
      weekSwitch: "week",
      valueLoad: 0,
      query: false,
      show: true,
      interval: 0,
      typeCal: "week",
      messageNotif: "",
      snackbarMessage: false,
      value: "",
      alpha: false,
      notification: false,
      snackbarAdmin: false,
      serviceLundi: false,
      serviceMardi: false,
      serviceMercredi: false,
      serviceJeudi: false,
      serviceVendredi: false,
      serviceSamedi: false,
      serviceDimanche: false,
      serviceLundi2: false,
      serviceMardi2: false,
      serviceMercredi2: false,
      serviceJeudi2: false,
      serviceVendredi2: false,
      serviceSamedi2: false,
      serviceDimanche2: false,
      dialogService: false,
      updateField: false,
      titleService: "",
      descriptionService: "",
      categorieService: "",
      idService: null,
      serviceDimancheBegin: null,
      serviceDimancheEnd: null,
      serviceLundiBegin: null,
      serviceLundiEnd: null,
      serviceMardiBegin: null,
      serviceMardiEnd: null,
      snackbar: false,
      serviceMercrediBegin: null,
      serviceMercrediEnd: null,
      serviceJeudiBegin: null,
      serviceJeudiEnd: null,
      serviceVendrediBegin: null,
      serviceVendrediEnd: null,
      serviceSamediBegin: null,
      serviceSamediEnd: null,
      prop: {},
      rooms: [],
      titleEvent: "",
      idEvent: 1,
      pickerEvent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      timeEvent: dt.getHours() + ":00",
      timeEndEvent: dt.getHours() + 1 + ":00",
      events: [],
      services: [],
      colors: [
        "#003e5c",
        "#91be1e",
        "#673AB7",
        "#00BCD4",
        "#4CAF50",
        "#FF9800",
        "#757575",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      selectedEvent: {},
      selectedElement: null,
      dialog: false,
      dialogAdd: false,
      dialogAddTitle: "Ajouter une animation",
      typeAdd: "animation",
      search: "",
      etb: {},
    };
  },
  mounted() {
    // this.$refs.calendar.checkChange()
  },
  methods: {
    noInversion(e) {
      let firstHour = e.target.closest('.col-1').querySelector("input[type='text']").value
      let secondHour = e.target.closest('.col-1').querySelector(".afterHour input[type='text']").value

      if (parseInt(firstHour) > parseInt(secondHour)) {
        this.showInversion = true
      } else {
        this.showInversion = false
      }
    },
    reseting() {
      this.serviceLundi2 = false;
      this.serviceMardi2 = false;
      this.serviceMercredi2 = false;
      this.serviceJeudi2 = false;
      this.serviceVendredi2 = false;
      this.serviceSamedi2 = false;
      this.serviceDimanche2 = false;

      this.serviceDimancheBegin = null;
      this.serviceDimancheEnd = null;
      this.serviceLundiBegin = null;
      this.serviceLundiEnd = null;
      this.serviceMardiBegin = null;
      this.serviceMardiEnd = null;
      this.serviceMercrediBegin = null;
      this.serviceMercrediEnd = null;
      this.serviceJeudiBegin = null;
      this.serviceJeudiEnd = null;
      this.serviceVendrediBegin = null;
      this.serviceVendrediEnd = null;
      this.serviceSamediBegin = null;
      this.serviceSamediEnd = null;
      this.titleService = "";
      this.descriptionService = "";
      this.categorieService = "";
      this.dialogService = false;
    },
    sendMessage() {
      let dt = new Date();
     let hour =  + dt.getHours(); 
      if( hour >= 6 && hour < 20){

      if (window.confirm("Souhaitez-vous envoyer le message aux résidents ?")) {
        xhr
        .post("/sendNotif", {  message: this.etb.annonce, rooms: this.rooms,  mode: this.modeNotification })
        .then((res) => {
        });
      }
      }
    },
    updateService(service) {
      this.updateField = true;
      this.serviceLundi2 = false;
      this.serviceMardi2 = false;
      this.serviceMercredi2 = false;
      this.serviceJeudi2 = false;
      this.serviceVendredi2 = false;
      this.serviceSamedi2 = false;
      this.serviceDimanche2 = false;
      this.idService = service.id;

      this.titleService = service.title;
      this.categorieService = service.category;
      this.descriptionService = service.description;
      service.horaires.forEach((e) => {
        if (e.title == "lundi") {
          this.serviceLundi2 = true;
          this.serviceLundiBegin = e.begin;
          this.serviceLundiEnd = e.end;
        } else if (e.title == "mardi") {
          this.serviceMardi2 = true;
          this.serviceMardiBegin = e.begin;
          this.serviceMardiEnd = e.end;
        } else if (e.title == "mercredi") {
          this.serviceMercredi2 = true;
          this.serviceMercrediBegin = e.begin;
          this.serviceMercrediEnd = e.end;
        } else if (e.title == "jeudi") {
          this.serviceJeudi2 = true;
          this.serviceJeudiBegin = e.begin;
          this.serviceJeudiEnd = e.end;
        } else if (e.title == "vendredi") {
          this.serviceVendredi2 = true;
          this.serviceVendrediBegin = e.begin;
          this.serviceVendrediEnd = e.end;
        } else if (e.title == "samedi") {
          this.serviceSamedi2 = true;
          this.serviceSamediBegin = e.begin;
          this.serviceSamediEnd = e.end;
        } else if (e.title == "dimanche") {
          this.serviceDimanche2 = true;
          this.serviceDimancheBegin = e.begin;
          this.serviceDimancheEnd = e.end;
        }
      });
      this.dialogService = true;
      this.updateField = false;
      this.saveFiche();
    },
    saveFiche() {
      this.snackbar = true;
      xhr
        .post("/store", {
          id: this.etb._id,
          services: this.services,
          events: this.events,
          menu: this.etb.menu,
          name: this.etb.infos.name,
          infos: this.etb.infos,
          horaires: this.etb.horaires,
          annonce: this.etb.annonce,
          restaurant: this.etb.restaurant,
        })
        .then(async (req, res) => {
        });
    },
    storeService() {
      let obj = {
        id: Math.floor(Math.random() * 1000),
        title: this.titleService,
        description: this.descriptionService,
        category: this.categorieService,
        horaires: [],
      };

      if (this.serviceLundi2 && this.serviceLundiBegin) {
        obj.horaires.push({
          title: "lundi",
          begin: this.serviceLundiBegin,
          end: this.serviceLundiEnd,
        });
      }

      if (this.serviceMardi2 && this.serviceMardiBegin) {
        obj.horaires.push({
          title: "mardi",
          begin: this.serviceMardiBegin,
          end: this.serviceMardiEnd,
        });
      }

      if (this.serviceMercredi2 && this.serviceMercrediBegin) {
        obj.horaires.push({
          title: "mercredi",
          begin: this.serviceMercrediBegin,
          end: this.serviceMercrediEnd,
        });
      }

      if (this.serviceJeudi2 && this.serviceJeudiBegin) {
        obj.horaires.push({
          title: "jeudi",
          begin: this.serviceJeudiBegin,
          end: this.serviceJeudiEnd,
        });
      }

      if (this.serviceVendredi2 && this.serviceVendrediBegin) {
        obj.horaires.push({
          title: "vendredi",
          begin: this.serviceVendrediBegin,
          end: this.serviceVendrediEnd,
        });
      }

      if (this.serviceSamedi2 && this.serviceSamediBegin) {
        obj.horaires.push({
          title: "samedi",
          begin: this.serviceSamediBegin,
          end: this.serviceSamediEnd,
        });
      }

      if (this.serviceDimanche2 && this.serviceDimancheBegin) {
        obj.horaires.push({
          title: "dimanche",
          begin: this.serviceDimancheBegin,
          end: this.serviceDimancheEnd,
        });
      }

      this.services.push(obj);
      this.titleService = "";
      this.descriptionService = "";
      this.categorieService = "";
      this.dialogService = false;
      this.saveFiche();
    },
    imgService(cat) {
      if (cat === "restaurant") {
        return "https://www.lecoindesentrepreneurs.fr/wp-content/uploads/2016/04/ouvrir-un-restaurant-1.png";
      } else if (cat === "coiffeur") {
        return "https://static.actu.fr/uploads/2021/03/coupe-deux-euros-salon-coiffure-rouen-etudiants.jpg";
      } else if (cat === "manucure") {
        return "https://www.treatwell.fr/identite/wp-content/uploads/sites/43/2018/05/ezgif-1-c73cda2a03.jpg";
      } else if (cat === "sauna") {
        return "https://www.peninsula.com/fr/-/media/images/paris/new/wellness/pool/ppr-sauna-1074.jpg?mw=987&hash=CA692916600A35AAF59F934646E6C67F66727436";
      } else if (cat === "cinéma") {
        return "https://cdn.radiofrance.fr/s3/cruiser-production/2020/12/5b5a3d8d-9654-4ebb-9a99-5f8e6169c6c6/838_gettyimages-108387402.jpg";
      } else if (cat === "fitness center") {
        return "https://media.istockphoto.com/photos/empty-gym-picture-id1132006407?k=20&m=1132006407&s=612x612&w=0&h=Z7nJu8jntywb9jOhvjlCS7lijbU4_hwHcxoVkxv77sg=";
      } else {
        return "https://www.service-sens.com/wp-content/uploads/2019/02/service-client.jpeg";
      }
    },
    nextCal() {
      this.$refs.calendar.next();
      //  this.events = [...this.events]

      //   this.$refs.calendar.$forceUpdate();
    },
    allowedStep: (m) => m % 5 === 0,
    removeEvent(e) {
      this.events = this.events.filter((e) => e.id !== this.idEvent);
      this.dialog = false;
      this.saveFiche();
    },
    removeService(title) {
      if (title) {
        this.services = this.services.filter((e) => e.title !== title);
        this.saveFiche();
      }
    },
    updatedService() {
      let obj = {
        id: Math.floor(Math.random() * 1000),
        title: this.titleService,
        description: this.descriptionService,
        category: this.categorieService,
        horaires: [],
      };

      if (this.serviceLundi2) {
        obj.horaires.push({
          title: "lundi",
          begin: this.serviceLundiBegin,
          end: this.serviceLundiEnd,
        });
      }

      if (this.serviceMardi2) {
        obj.horaires.push({
          title: "mardi",
          begin: this.serviceMardiBegin,
          end: this.serviceMardiEnd,
        });
      }

      if (this.serviceMercredi2) {
        obj.horaires.push({
          title: "mercredi",
          begin: this.serviceMercrediBegin,
          end: this.serviceMercrediEnd,
        });
      }

      if (this.serviceJeudi2) {
        obj.horaires.push({
          title: "jeudi",
          begin: this.serviceJeudiBegin,
          end: this.serviceJeudiEnd,
        });
      }

      if (this.serviceVendredi2) {
        obj.horaires.push({
          title: "vendredi",
          begin: this.serviceVendrediBegin,
          end: this.serviceVendrediEnd,
        });
      }

      if (this.serviceSamedi2) {
        obj.horaires.push({
          title: "samedi",
          begin: this.serviceSamediBegin,
          end: this.serviceSamediEnd,
        });
      }

      if (this.serviceDimanche2) {
        obj.horaires.push({
          title: "dimanche",
          begin: this.serviceDimancheBegin,
          end: this.serviceDimancheEnd,
        });
      }

      this.services = this.services.map((p) =>
        p.id == this.idService ? obj : p
      );

      this.serviceLundi2 = false;
      this.serviceMardi2 = false;
      this.serviceMercredi2 = false;
      this.serviceJeudi2 = false;
      this.serviceVendredi2 = false;
      this.serviceSamedi2 = false;
      this.serviceDimanche2 = false;
      this.dialogService = false;
      this.saveFiche();
    },
    async updateEvent() {

      this.createEvent = {
        name: this.titleEvent,
        color: this.typeAdd === "animation" ? this.colors[0] : this.colors[1],
        start: `${this.pickerEvent} ${this.timeEvent}`,
        end: `${this.pickerEvent} ${this.timeEndEvent}`,
        notification: this.notification,
        timed: true,
      };

      let valiDate = moment(`${this.pickerEvent} ${this.timeEvent}`);
      let between = this.events.some((ev) =>
        (ev.id != this.idEvent) && valiDate.add('minute', 1).isBetween(ev.start, ev.end)
      )
      between = false
      if (!valiDate.isValid() || this.titleEvent == "") {
        this.$notify({
          group: 'foo',
          type: 'error',
          title: 'Animation invalide',
          text: "Votre date et/ou heure et/ou titre d'animation est invalide !"
        });

      } else if (between) {
        this.$notify({
          group: 'foo',
          type: 'error',
          title: 'Animation déjà prise',
          text: "Votre créneau d'animation est déjà prise !!!"
        });
      } else {

        this.events = this.events.map((p) =>
          p.id == this.idEvent ? { id: p.id, ...this.createEvent } : p
        );

        this.saveFiche();
        this.dialog = false;
      }
    },
    storeNewEvent() {
      let valiDate = moment(`${this.pickerEvent} ${this.timeEvent}`);
      let between = this.events.some((ev) =>
        valiDate.add('minute', 1).isBetween(ev.start, ev.end)
      )
      between = false
      if (!valiDate.isValid() || this.titleEvent == "") {
        this.$notify({
          group: 'foo',
          type: 'error',
          title: 'Animation invalide',
          text: "Votre date et/ou heure et/ou titre d'animation est invalide !"
        });

      } else if (between) {
        this.$notify({
          group: 'foo',
          type: 'error',
          title: 'Animation déjà prise',
          text: "Votre créneau d'animation est déjà prise !"
        });
      } else {
        this.createEvent = {
          id: Math.floor(Math.random() * 1000),
          name: this.titleEvent,
          notification: this.notification,
          color: this.typeAdd === "animation" ? this.colors[0] : this.colors[1],
          start: `${this.pickerEvent} ${this.timeEvent}`,
          end: `${this.pickerEvent} ${this.timeEndEvent}`,
          timed: true,
        };

        this.events = [...this.events, this.createEvent];
        this.saveFiche();
        this.notification = false
        this.dialogAdd = false;
      }

    },
    monthAnimation() {
      this.typeCal = "month";
    },
    weekAnimation() {
      this.typeCal = "week";
    },
    addAnimation() {
      this.dialogAddTitle = "Ajouter une animation";
      this.dialogAdd = true;
      this.typeAdd = "animation";
      this.titleEvent = "";
      if (dt.getHours()) {
        this.timeEvent = dt.getHours() + ":00";
        this.timeEndEvent = dt.getHours() + 1 + ":00";
      } else {
        this.timeEvent = "10:00";
        this.timeEndEvent = "13:00";
      }
    },
    addAnimationMonth({ date }) {
      this.dialogAddTitle = "Ajouter une animation";
      this.dialogAdd = true;
      this.typeAdd = "animation";
      this.titleEvent = "";
      this.pickerEvent = date;
    },
    addService() {
      this.dialogAddTitle = "Ajouter un service";
      this.dialogAdd = true;
      this.typeAdd = "service";
      this.titleEvent = "";
      this.timeEvent = dt.getHours() + ":00";
      this.timeEndEvent = dt.getHours() + 1 + ":00";
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    showEvent({ nativeEvent, event, date }) {
      this.idEvent = event.id;
      this.dialog = true;
      this.selectedEvent = event;
      this.titleEvent = event.name;
      let start = new Date(event.start);
      let end = new Date(event.end);
      this.pickerEvent = event.start.substr(0, 10);
      this.timeEvent = start.getHours() + ":" + start.getMinutes();
      this.timeEndEvent = end.getHours() + ":" + end.getMinutes();
      this.notification = event.notification

      //   start: `${this.pickerEvent} ${this.timeEvent}`,
      // end: `${this.pickerEvent} ${this.timeEndEvent}`,
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        const start = new Date(this.createStart);

        this.dialogAddTitle = "Ajouter un service";
        this.dialogAdd = true;
        this.typeAdd = "service";
        this.titleEvent = "";
        (this.pickerEvent = start.toISOString().substr(0, 10)),
          (this.timeEvent = start.getHours() + ":00");
        this.timeEndEvent = start.getHours() + 1 + ":00";
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
          ? `rgba(${r}, ${g}, ${b}, 0.7)`
          : event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
  },
  watch: {
    timeEvent(val){
      var timeAndDate = moment(val, "HH");
      var timeLimit = moment("19", "HH");
      var timeLimitTwo = moment("06", "HH");
      if(timeAndDate.isAfter(timeLimit) || timeAndDate.isBefore(timeLimitTwo)){
        console.log(true, "val");
        this.notification = false
        this.disabledNotif = true
      }else{
        this.disabledNotif = false
      }
    },
    serviceLundi(val) {
      if (val === false) {
        this.etb.horaires.lundi.begin = "";
        this.etb.horaires.lundi.end = "";
      }
    },
    serviceMardi(val) {
      if (val === false) {
        this.etb.horaires.mardi.begin = "";
        this.etb.horaires.mardi.end = "";
      }
    },
    serviceMercredi(val) {
      if (val === false) {
        this.etb.horaires.mercredi.begin = "";
        this.etb.horaires.mercredi.end = "";
      }
    },
    serviceJeudi(val) {
      if (val === false) {
        this.etb.horaires.jeudi.begin = "";
        this.etb.horaires.jeudi.end = "";
      }
    },
    serviceVendredi(val) {
      if (val === false) {
        this.etb.horaires.vendredi.begin = "";
        this.etb.horaires.vendredi.end = "";
      }
    },
    serviceSamedi(val) {
      if (val === false) {
        this.etb.horaires.samedi.begin = "";
        this.etb.horaires.samedi.end = "";
      }
    },
    serviceDimanche(val) {
      if (val === false) {
        this.etb.horaires.dimanche.begin = "";
        this.etb.horaires.dimanche.end = "";
      }
    },
    weekSwitch(val) {
    },
  },
  computed: {
    isValidHours(){
      let dt = new Date()
      let hour =  + dt.getHours(); 
      return hour >= 6 && hour < 20
    },

    okService() {
      let tab = this.services.map((e) => e.title.toLowerCase())
      return !this.titleService.length || tab.includes(this.titleService.toLowerCase())
    }
  },
  created() {
    this.dateNext = [
      moment().add(1, "weeks").day(1),
      moment().add(1, "weeks").day(2),
      moment().add(1, "weeks").day(3),
      moment().add(1, "weeks").day(4),
      moment().add(1, "weeks").day(5),
      moment().add(1, "weeks").day(6),
      moment().add(1, "weeks").day(7),
    ];

    this.dateNow = [
      moment().day(1),
      moment().day(2),
      moment().day(3),
      moment().day(4),
      moment().day(5),
      moment().day(6),
      moment().day(7),
    ];
    let user = localStorage.getItem("user");
    let prop = localStorage.getItem("prop");
    let etbId = localStorage.getItem("etbId");
    prop = JSON.parse(prop);
    if (user) {
      user = JSON.parse(user);
      setTimeout(() => {
        this.snackbarMessage = true;
        this.messageNotif = `Bienvenue ${user.name} !`;
      }, 2000);
    }

    if (prop) {
      this.prop = prop;
    }
    if (localStorage.getItem("rooms")) {
      this.rooms = JSON.parse(localStorage.getItem("rooms"));
    }
    xhr.post("/load", { id: etbId }).then((res) => {
      this.etb = res.data;
      this.serviceLundi =
        res.data.horaires.lundi.begin != undefined &&
        res.data.horaires.lundi.begin != "";
      this.serviceMardi =
        res.data.horaires.mardi.begin != undefined &&
        res.data.horaires.mardi.begin != "";
      this.serviceMercredi =
        res.data.horaires.mercredi.begin != undefined &&
        res.data.horaires.mercredi.begin != "";
      this.serviceJeudi =
        res.data.horaires.jeudi.begin != undefined &&
        res.data.horaires.jeudi.begin != "";
      this.serviceVendredi =
        res.data.horaires.vendredi.begin != undefined &&
        res.data.horaires.vendredi.begin != "";
      this.serviceSamedi =
        res.data.horaires.samedi.begin != undefined &&
        res.data.horaires.samedi.begin != "";
      this.serviceDimanche =
        res.data.horaires.dimanche.begin != undefined &&
        res.data.horaires.dimanche.begin != "";
      this.services = res.data.services;
      if (!this.events.length) {
        this.events = res.data.events;
      }
    });
  },
};
</script>